import React from 'react';

import NextLink from 'next/link';

const Link = ({ to, ...props }) => (
  <NextLink href={to} legacyBehavior>
    <a {...props} />
  </NextLink>
);

export default Link;
