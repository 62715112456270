import React from 'react';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import MobileNav from './Nav/MobileNav';
import DesktopNav from './Nav/DesktopNav';

const menu = [
  { name: 'Current Developments', url: '/developments' },
  { name: 'Coming Soon', url: '/forthcoming' },
  { name: 'About Us', url: '/about' },
  {
    name: 'Distinctly Aquinna',
    submenu: [
      { name: 'Land Opportunities', url: '/land' },
      { name: 'Joint Ventures', url: '/joint-ventures' },
      { name: 'Bespoke Design', url: '/design' },
      { name: 'Build Contracting', url: '/contracting' },
    ],
  },
  { name: 'Gallery', url: '/gallery' },
  { name: 'News', url: '/news' },
  { name: 'Contact Us', url: '/contact' },
];

const Nav = () => {
  const router = useRouter();
  const showNavLine = router.pathname !== '/';
  return (
    <header>
      <DesktopNav showNavLine={showNavLine} />
      <MobileNav menu={menu} />
      {showNavLine && <div id="nav-line" className="hidden-xs hidden-ms" />}
    </header>
  );
};

Nav.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }),
};

export default Nav;
