import React from 'react';
import useTracking from '../../utils/useTracking';
import Footer from './Footer';
import Nav from './Nav';

const Layout = ({ children }) => {
  useTracking();

  return (
    <div className="app" id="app">
      <Nav />
      <div id="content" className="clearfix">
        {children}
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
