import React, { Component, Fragment } from 'react';
import Link from '../../common/Link';

export default class MobileNav extends Component {
  constructor() {
    super();
    this.state = {
      collapsed: true,
      aquinnaOpen: false,
    };
  }

  toggleCollapse = () => {
    const { collapsed } = this.state;
    this.setState({ collapsed: !collapsed });
  };

  toggleAquinnaOpen = () => {
    const { aquinnaOpen } = this.state;
    this.setState({ aquinnaOpen: !aquinnaOpen });
  };

  closeNav = () => {
    this.setState({ collapsed: true });
  };

  render() {
    const { collapsed, aquinnaOpen } = this.state;
    const navClass = collapsed ? 'collapse' : '';
    const aquinnaClass = aquinnaOpen ? 'open' : '';
    const { menu } = this.props;

    return (
      <nav className="navbar navbar-default visible-xs visible-ms">
        <div className="container-fluid">
          <div className="navbar-header">
            <button type="button" className="navbar-toggle" onClick={this.toggleCollapse}>
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <Link className="navbar-brand" to="/">
              Aquinna Homes
            </Link>
          </div>
          <div id="navbar" className={`navbar-collapse ${navClass}`}>
            <ul className="nav navbar-nav" role="menu">
              {menu.map((item) => (
                <li
                  key={item.name}
                  className={item.submenu ? `dropdown ${aquinnaClass}` : undefined}
                >
                  {item.submenu ? (
                    <Fragment>
                      {/* eslint-disable-next-line */}
                      <a onClick={this.toggleAquinnaOpen}>
                        Distinctly Aquinna <span className="caret" />
                      </a>
                      <ul className="dropdown-menu" role="menu">
                        {item.submenu.map((subItem) => (
                          <li key={subItem.name} role="presentation">
                            <Link to={subItem.url} role="menuitem" onClick={this.closeNav}>
                              {subItem.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  ) : (
                    <Link to={item.url} onClick={this.closeNav}>
                      {item.name}
                    </Link>
                  )}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    );
  }
}
