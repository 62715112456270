import { useEffect } from 'react';
import { useRouter } from 'next/router';
import config from '../config';

const formatUrl = (pathname: string) =>
  pathname
    .split('/')
    .map((part) => (/^[a-f0-9]{24}$/.test(part) ? '<id>' : part))
    .join('/');

const trackPageView = (pathname: string, title: string, trackingId: string) => {
  window.gtag?.('config', trackingId, {
    page_title: title,
    page_path: formatUrl(pathname),
  });
};

const useTracking = (trackingId: string | undefined = config.gaTrackingId) => {
  const router = useRouter();

  useEffect(() => {
    if (!trackingId) return;

    const handleRouteChange = () => {
      trackPageView(window.location.pathname, document.title, trackingId);
    };

    router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, [router.events]);
};

export const trackEvent = (
  action: string,
  { label, category, nonInteraction, ...event }: EventArgs
) => {
  if (process.env.NODE_ENV === 'development') console.log('event', action, category, label);
  window.gtag?.('event', action, {
    event_category: category,
    event_label: label,
    non_interaction: nonInteraction,
    ...event,
    send_to: config.gaTrackingId,
  });
};

export interface EventArgs {
  /** Typically the object that was interacted with (e.g. 'Video') */
  category: string;
  /** Useful for categorizing events (e.g. 'Fall Campaign') */
  label?: string;
  /** A numeric value associated with the event (e.g. 42) */
  value?: number;
  /** Specifies that a hit be considered non-interactive. */
  nonInteraction?: boolean;
  /**
   * This specifies the transport mechanism with which hits will be sent.
   * The options are 'beacon', 'xhr', or 'image'. By default, analytics.js
   * will try to figure out the best method based on the hit size and browser
   * capabilities. If you specify 'beacon' and the user's browser does not support
   * the `navigator.sendBeacon` method, it will fall back to 'image' or 'xhr'
   * depending on hit size.
   */
  transport?: 'beacon' | 'xhr' | 'image';
  /** Custom dimensions */
  dimension1?: string;
  dimension2?: string;
  dimension3?: string;
  dimension4?: string;
  dimension5?: string;
  dimension6?: string;
  dimension7?: string;
  dimension8?: string;
  dimension9?: string;
  dimension10?: string;
  dimension11?: string;
  dimension12?: string;
  dimension13?: string;
  dimension14?: string;
  dimension15?: string;
  dimension16?: string;
  dimension17?: string;
  dimension18?: string;
  dimension19?: string;
  dimension20?: string;
  /** Custom metrics */
  metric1?: number;
  metric2?: number;
  metric3?: number;
  metric4?: number;
  metric5?: number;
  metric6?: number;
  metric7?: number;
  metric8?: number;
  metric9?: number;
  metric10?: number;
  metric11?: number;
  metric12?: number;
  metric13?: number;
  metric14?: number;
  metric15?: number;
  metric16?: number;
  metric17?: number;
  metric18?: number;
  metric19?: number;
  metric20?: number;
}

export default useTracking;
