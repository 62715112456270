import React from 'react';
import { getSetting } from '../common/HelperFunctions';
import Link from '../common/Link';

const links = [
  { name: 'About Us', url: '/about' },
  { name: 'Contact Us', url: '/contact' },
  { name: 'Privacy', url: '/privacy' },
  { name: 'Legal', url: '/legal' },
  { name: 'Careers', url: '/careers' },
  { name: 'Consumer Code', url: '/consumer-code' },
  { name: 'Complaints Procedure', url: '/complaints' },
];

const Footer = () => {
  let currentYear = getSetting('copyright', new Date().getFullYear());
  if (currentYear < 2017) {
    currentYear = 2017;
  }
  return (
    <footer>
      <div className="footer">
        <div id="footer-main" className="clearfix container">
          <div className="clearfix center-block social col-sm-12">
            <a
              href="https://www.instagram.com/aquinnahomes"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Instagram"
            >
              <i className="icon-s-insta icon-2x" />
            </a>
            <a
              href="https://www.linkedin.com/company/aquinna-homes"
              target="_blank"
              rel="noopener noreferrer"
              aria-label="Linked In"
            >
              <i className="icon-s-linked icon-2x" />
            </a>
            <a href="mailto:info@aquinnahomes.com" aria-label="Email Aquinna Homes">
              <i className="icon-envelope-square icon-2x" />
            </a>
          </div>
          <div className="links col-sm-12 clearfix">
            {links.map((link) => (
              <div key={link.url} className="col-sm-4">
                <p>
                  <Link to={link.url}>{link.name}</Link>
                </p>
              </div>
            ))}
          </div>
        </div>
        <p id="copyright" className="small">
          &copy; Aquinna Homes plc {currentYear}
          <br />
          All Rights Reserved
        </p>
        <p className="small">Registered office: 17 London End, Beaconsfield, HP9 2HN</p>
      </div>
      <p className="cookie-footer">
        We use cookies on this website to give you the best possible experience. For more
        information, see our <Link to="/cookie-policy">Cookie Policy</Link>.
      </p>
    </footer>
  );
};

export default Footer;
