import React from 'react';
import Head from 'next/head';
import Layout from '../components/layout/Layout';

import '../sass/app.scss';
import '../components/home/Welcome.module.scss';
import 'react-image-gallery/styles/scss/image-gallery-no-icon.scss';

export default function App({ Component, pageProps }) {
  return (
    <Layout>
      <Head>
        <title>Aquinna Homes</title>
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta
          name="description"
          content="Aquinna Homes is a South East England residential developer based in Beaconsfield, building exceptional homes throughout Buckinghamshire, Surrey, Sussex, Hampshire, Kent, Berkshire, Oxfordshire and London."
        />
      </Head>
      <Component {...pageProps} />
    </Layout>
  );
}
