function plural(number) {
  if (number !== 1) return 's';
  return '';
}

/**
 * Comma separate words if exist
 */
function comma(...words) {
  return words.filter(Boolean).join(', ');
}

function moneyFormat(amount) {
  return amount.toLocaleString('en-GB', {
    style: 'currency',
    currency: 'GBP',
    minimumFractionDigits: 0
  });
}

/**
 * Format price including dash for range
 * @param {number} min Min price
 * @param {number} [max] Max price
 */
function priceFormat(min, max, hidePound) {
  if (min) {
    if (max && min !== max) return `${moneyFormat(min)} - ${moneyFormat(max)}`;
    return moneyFormat(min);
  }
  return hidePound ? 'TBR' : '£ TBR';
}

function statusFormat(status) {
  switch (status) {
    case 'awaiting':
      return 'TBR';
    default:
      return status.toUpperCase();
  }
}

function getSetting(setting, defaultSetting) {
  if (typeof window !== 'undefined' && window.settings && window.settings[setting]) {
    return window.settings[setting];
  }
  return defaultSetting;
}

/**
 * Format number of beds including dash for range
 * @param {number} min Min beds
 * @param {number} [max] Max beds
 */
function bedsFormat(min, max) {
  if (min) {
    if (max && min !== max) return `${min} - ${max} Beds`;
    return `${min} Bed${plural(min)}`;
  }
  return 'TBR';
}

function getDate(dateString) {
  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ];

  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = monthNames[date.getMonth()];

  return `${month} ${year}`;
}

export { moneyFormat, priceFormat, statusFormat, bedsFormat, plural, comma, getDate, getSetting };
